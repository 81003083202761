export function debounce(func: Function, wait: number) {
  let timeout: any = null;

  return function () {
    const action = () => {
      clearTimeout(timeout);
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(action, wait);
  };
}

/**
 * Recursively traverse an object in search of the value at the end of the provided dotpath.
 *
 * @param {*} object
 * @param {*} keys a stringified dotpath e.g student.id
 */
export function getValue(object: any, keys: any): string {
  if (typeof keys === "string") keys = keys.split(".");

  const value = object[keys.shift()];

  if (keys.length) {
    return getValue(value, keys);
  } else {
    return value;
  }
}

/**
 * Return all object values in a string[]
 * 
 * @param object 
 */
function getValues(object: any, values: string[] = []): string[] {
  return Object.values(object).reduce((result: string[], item: any) => {
    if (typeof item === 'object' && item !== null) {
      return getValues(item, values)
    }

    result.push(item)
    return result
  }, values)
}

export function filterByKey(data: any, key: string, filter: string): boolean {
  const value = getValue(data, key);
  if (value) {
    return value.toString().toLowerCase().includes(filter);
  }

  return false
}

export function filterByAny(data: any, filter: string): boolean {
  const result = getValues(data).map((value: string) => {
    if (value) {
      return value.toString().toLowerCase().includes(filter);
    }
    return false
  }).find((item: boolean) => item === true);

  return result || false;
}