import React, { ChangeEventHandler } from "react";

type TProps = {
  placeholder?: string;
  onChange: (event: any) => ChangeEventHandler<HTMLInputElement>
}

const Filter = ({ onChange, placeholder = "" }: TProps) => {

  return (
    <div>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 69" fill="currentColor" aria-hidden="true">
            <path d="M67.6,7.7L43.1,32.1v32.5c0,3.7-4.4,5.6-7.1,3.2l-8.6-7.5c-0.8-0.7-1.5-2.2-1.5-3.2v-25L1.5,7.7c-2.7-2.7-0.8-7.3,3-7.3h60
                  C68.4,0.3,70.3,5,67.6,7.7z M64.6,4.6h-60l25.7,25.7v26.8l8.6,7.5V30.3L64.6,4.6z"/>
          </svg>
        </div>
        <input
          type="text"
          name="filteranything"
          id="filteranything"
          onChange={onChange}
          autoComplete="off"
          className="
            focus:ring-blue-400
            focus:outline-none
            focus:border-blue-400
            focus:z-10
            block
            w-full
            pl-8
            py-3
            h-12
            sm:text-sm
            border
            border-gray-300
            rounded-md"
          placeholder={placeholder}
        />
      </div>
    </div>

  )
}
export default Filter;
