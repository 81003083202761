import React, { Component } from "react";

export class BreadCrumbs extends Component {
  render() {
    const { crumbs, separator } = this.props; // initializing the props that we pass while using this component.
    const arrowIcon = separator ? null : <span className="text-gray-500 mx-2">/</span>;

    return (
      <div>
        {crumbs && crumbs.length && (
          <div>
            {crumbs.map((l, key) => (
              <React.Fragment>
                {key !== 0 && arrowIcon}
                {l.link === undefined ? (
                  <span className="text-gray-500">{l.title}</span>
                ) : (
                  <a className="self-center mt-1" href={l.link}>
                    {l.title}
                  </a>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }
}
