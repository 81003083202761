import { ColumnApi } from "ag-grid-community/dist/lib/columnController/columnApi";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { GridReadyEvent } from "ag-grid-community/dist/lib/events";
import { GridApi } from "ag-grid-community/dist/lib/gridApi";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
//@ts-ignore
import { AllModules, LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import styled from "styled-components";
import ExportButton, { OptionClass } from "./components/Export";
import Pagination from "./components/Pagination";
import Filter from "./components/Filter";
import { TAction, TProps, TState } from "./lib/types";
import { debounce, filterByAny, filterByKey } from "./lib/utilities";
//@ts-ignore
import Button from "@alliancesafetycouncil/asc-button";
import "./table.css";


// Set AG Grid License Key
LicenseManager.setLicenseKey(
  "CompanyName=Alliance Safety Council,LicensedGroup=Pyvot Track,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-015918,ExpiryDate=26_May_2022_[v2]_MTY1MzUxOTYwMDAwMA==ae6296bfafd09a8532cfa67e23f92a30"
);

const Search = styled.div`
  justify-content: flex-start;
  margin-bottom: 10px;
`;

Search.defaultProps = {
  className: "Search"
}

const FilterContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

FilterContainer.defaultProps = {
  className: "FilterContainer"
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

InputContainer.defaultProps = {
  className: "InputContainer"
}

const BulkActionContainer = styled.div<{ show: number }>`
  background: #f0f6fa;
  flex: 1;
  margin-right: 10px;
  text-align: right;
  padding: 10px;
  border-radius: 12px;
  opacity: ${({ show }) => (show ? "1" : "0.5")};
`;

BulkActionContainer.defaultProps = {
  className: "BulkActionContainer"
}

const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

ExportContainer.defaultProps = {
  className: "ExportContainer"
}

const SearchIcon = styled.span`
  position: absolute;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
`;

SearchIcon.defaultProps = {
  className: "SearchIcon"
}

const SearchInput = styled.input`
  height: 39px;
  padding-left: 35px;
  border: 1px solid #aaa;
  border-radius: 4px;
`;

SearchInput.defaultProps = {
  className: "SearchInput"
}

const SelectInput = styled.select`
  width: 210px;
  height: 39px;
  margin-right: 12px;
  padding: 0px 12px;
  border: 1px solid #aaa;
  border-radius: 4px;

  option {
    color: #1b91c7;
  }
`;

SelectInput.defaultProps = {
  className: "SelectInput"
}

const BulkAction = styled(SelectInput)`
  margin-right: 0px;
  width: 235px;
`;

BulkAction.defaultProps = {
  className: "BulkAction"
}

const GridContainer = styled.div`
  height: 100%;
  position: relative;
`;

GridContainer.defaultProps = {
  className: "GridContainer"
}

const SelectedContainer = styled.div`
  color: #1b91c7;
  display: inline;
  margin-right: 12px;
  font-size: 14px;
`;

SelectedContainer.defaultProps = {
  className: "SelectedContainer"
}

const OverlayContainer = styled.div`
  position: absolute;
  background: white;
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 60px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 4px;
  left: 6px;
  box-shadow: 0 0 5px 3px #fff;
  background: rgba(255, 255, 255, 0.75);
`;

OverlayContainer.defaultProps = {
  className: "OverlayContainer"
}

const Overlay = styled.div`
  transform: translateY(-40px);
`;

Overlay.defaultProps = {
  className: "Overlay"
}

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 15px;
`;

HeaderContainer.defaultProps = {
  className: "HeaderContainer"
};

let filter: string = "";

class Table extends Component<TProps, TState> {
  gridApi: GridApi | undefined;
  gridColumnApi: ColumnApi | undefined;

  public static defaultProps = {
    defaultColDef: {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
    },
    animateRows: false,
    useDefaultOverlay: false,
    enableExport: true
  };

  constructor(props: TProps) {
    super(props);

    this.state = {
      modules: AllModules,
      dropdownOpen: false,
      selected: props.selected,
      selection: undefined,
      filter: "",
      property: "",
      page: 1,
    };

    filter = "";
  }

  handleDebounce = debounce(() => {
    filter = this.state.filter.toLowerCase();
    this.handleFilterChanged();
  }, 1000);

  handleFilterChanged = (): void => {
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  };

  /**
   * Applies a default filter to the table
   *
   * @param column key of column to filter
   * @param value of filter
   */
  setDefaultFilter = (column: string, value: string) => {
    if (this.gridApi) {
      const instance = this.gridApi.getFilterInstance(column);

      if (instance) {
        //@ts-ignore
        instance.selectNothing();
        //@ts-ignore
        instance.selectValue(value);
        //@ts-ignore
        instance.applyModel();
      }

      this.gridApi.onFilterChanged();
    }
  };

  onRowDataChanged = () => {
    const { defaultFilter } = this.props;

    if (defaultFilter) {
      this.setDefaultFilter(defaultFilter.column, defaultFilter.value);
    }
  };

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: "property" | "filter"
  ): any => {
    const state: Pick<TState, keyof TState> = {
      ...this.state,
      [key]: event.currentTarget.value,
    };

    this.setState(state);

    this.handleDebounce();
  };

  isExternalFilterPresent = () => {
    return filter.length > 0;
  };

  /**
   * A custom filter that runs for every row, when a filter is set
   *
   * @param node a single row in the table
   * @returns boolean
   */
  doesExternalFilterPass = (node: RowNode): boolean => {
    const { filter_type } = this.props;
    const { property } = this.state;

    const row = Object.assign({}, node.data)
    delete node.data.network

    if (filter_type === "by_key" && property) {
      return filterByKey(row, property, filter)
    }

    return filterByAny(row, filter)
  };

  handleRowSelection = (data: any) => {
    this.setState({
      selection: data,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  export = (format: string) => {
    switch (format) {
      case 'csv':
        this.gridApi?.exportDataAsCsv();
        break;
      case 'excel':
        this.gridApi?.exportDataAsExcel();
        break;
      default:
        return false;
    }
  };

  onGridReady = (params: GridReadyEvent): void => {
    const { getGridApi, getGridColumnApi } = this.props;

    if (getGridApi) {
      getGridApi(params.api)
    }

    if (getGridColumnApi) {
      getGridColumnApi(params.columnApi)
    }

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  handleOverlay = () => {
    // disabled overlay
    if (this.props.disabled === true) {
      return (
        <OverlayContainer>
          <Overlay style={{ fontWeight: 'bold', padding: '10px', border: '2px solid red' }}>
            This feature has been disabled. <br />
            Please contact your administrator.
          </Overlay>
        </OverlayContainer>
      );
    }

    //busy overlay
    if (this.props.busy === true) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">Loading...</Overlay>
        </OverlayContainer>
      );
    }

    // no-rows overlay
    if (this.props.busy === false && this.props.res === true && !this.props.data.length) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">No Rows To Show</Overlay>
        </OverlayContainer>
      );
    }
  };

  getNextPage = async (page: number) => {
    // dont fetch if we already have this page
    if (page > this.state.page) {
      this.setState({ page })
      await this.props.getData(this.props.offset, true, page);
    }

    this.gridApi?.paginationGoToNextPage();
  };

  getPrevPage = () => {
    this.gridApi?.paginationGoToPreviousPage();
  };

  render() {
    const {
      selected,
      data,
      filter_type,
      className,
      defaultColDef,
      actions,
      customCells,
      columnDefs,
      animateRows,
      bulkAction,
      rowClassRules,
      rowHeight,
      useDefaultOverlay,
      enablePagination,
      busy,
      totalItems,
      pageSize,
      enableExport,
      headerConfig = []
    } = this.props;

    return (
      <div className={`flex flex-col`} style={{ height: "100%" }}>
        <Search className="search flex mb-4">
          {filter_type === "by_key" && (
            <FilterContainer>
              <InputContainer>
                <SelectInput onChange={(e) => this.handleChange(e, "property")}>
                  <option value=""></option>
                  {this.props.columnDefs.map((col: any) => {
                    return (
                      <option key={col.field} value={col.field}>
                        {col.headerName}
                      </option>
                    );
                  })}
                </SelectInput>
              </InputContainer>
              <InputContainer>
                <Filter
                  placeholder="Search"
                  onChange={(e) => this.handleChange(e, "filter")}
                />
              </InputContainer>
            </FilterContainer>
          )}
          {
            filter_type === "any" && (
              <FilterContainer>
                <InputContainer>
                  <Filter
                    placeholder="Search"
                    onChange={(e) => this.handleChange(e, "filter")}
                  />
                </InputContainer>
              </FilterContainer>
            )
          }
          {bulkAction && <BulkActionContainer show={selected ? 1 : 0}>
            <SelectedContainer>{selected ? selected : 0} Selected</SelectedContainer>
            <BulkAction onChange={(e) => this.handleChange(e, "property")}>
              <option value="">What do you want to do?</option>
              {actions &&
                actions.map((action: TAction, index: number) => {
                  return <option key={`${action.name}-${index}`}>{action.name}</option>;
                })}
            </BulkAction>
          </BulkActionContainer>}
          {enableExport && <ExportContainer>
            <ExportButton>
              <a className={OptionClass} role="menuitem" onClick={() => this.export("csv")}>
                CSV
              </a>
              <a className={OptionClass} role="menuitem" onClick={() => this.export("excel")}>
                Excel
              </a>
            </ExportButton>
          </ExportContainer>}
          <HeaderContainer>
            {headerConfig.map((button, index) => {
              return <Button href={button.href} key={`${index}-${button.size}-${button.label}`} onClick={button.action} type={button.type} size={button.size}>{button.label}</Button>
            })}
          </HeaderContainer>
        </Search>
        <GridContainer className={className}>
          <AgGridReact
            {...this.props}
            modules={AllModules}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onRowClicked={(e) => this.handleRowSelection(e.data)}
            frameworkComponents={customCells}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            onGridReady={this.onGridReady}
            onRowDataChanged={this.onRowDataChanged}
            rowData={data}
            animateRows={animateRows}
            rowHeight={rowHeight || 50}
            rowClassRules={rowClassRules}
            pagination={enablePagination}
            paginationPageSize={pageSize || undefined}
            suppressPaginationPanel={!useDefaultOverlay}
            suppressLoadingOverlay={!useDefaultOverlay}
            suppressNoRowsOverlay={!useDefaultOverlay}
          ></AgGridReact>
          {!useDefaultOverlay && this.handleOverlay()}
        </GridContainer>
        {enablePagination && (
          <div className={className}>
            <Pagination
              busy={busy}
              getPrevPage={this.getPrevPage}
              getNextPage={(page) => this.getNextPage(page)}
              pageSize={pageSize}
              totalItems={totalItems}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Table;
