import React, { useState, useRef } from "react";
//@ts-ignore
import Button from '@alliancesafetycouncil/asc-button';
import { Transition } from '@headlessui/react'
import { useEffect } from "react";

export const OptionClass = "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer";

const ExportButton = ({ children }: any) => {

  const [isOpen, setIsOpen] = useState(false)
  const [ref, setRef] = useState<any>(undefined)

  useEffect(() => {
    const ref = React.createRef();
    if (ref) setRef(ref)
  }, [])

  return (
    <div className="relative inline-block text-left">
      <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer" >
        <Button type="secondary" size="md">
          Export <i className="far fa-angle-down -mr-1 ml-1"></i>
        </Button>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {
          <div ref={ref} className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-md bg-white layer-top">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {children}
            </div>
          </div>
        }
      </Transition>
    </div>

  )
}
export default ExportButton;
